<template>
  <div class="giga-table_widget giga-margin_t30">
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <div
            class="border-raduis-md bg-white box-shadow-xl-dark p-4"
            style="height: 240px"
        >
          <div
              class="title d-flex justify-content-between align-center"
          >
            <b-skeleton
                variant="light"
                height="30px"
                width="60%"
            ></b-skeleton>

            <b-skeleton
                variant="light"
                height="30px"
                width="48px"
            ></b-skeleton>
          </div>
          <div class="card mt-4">
            <ul class="list-group list-group-flush">
              <li class="list-group-item small">
                <b-skeleton
                    variant="light"
                    height="15px"
                    width="100%"
                ></b-skeleton>
              </li>
              <li class="list-group-item small">
                <b-skeleton
                    variant="light"
                    height="15px"
                    width="100%"
                ></b-skeleton>
              </li>
              <li class="list-group-item small">
                <b-skeleton
                    variant="light"
                    height="15px"
                    width="100%"
                ></b-skeleton>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <div class="giga-widget_header">
        <h3 class="giga-widget_title">{{title}}</h3>
        <b-button :to="{ name: URL }" class="giga-widget_seeMore">Voir tout</b-button>
      </div>
      <div class="giga-widget_body">
        <table class="giga-table">
          <thead>
          <tr>
            <th>Nom de l’ouvrage</th>
            <th>Vol. Précaire</th>
            <th>Vol.Classique</th>
            <th>Nb. d’opérations</th>
            <th>Valorisation</th>
            <th>Statut</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="work in getWorks.content">
            <tr
                class="cursor-pointer"
                @click="onRowSelected(work.id)"
                :key="work.id"
            >
              <td><p>{{ work.name }}</p></td>
              <td><p>{{ work.precarityVolume | formatNumber }}<span> kWh cumac</span></p></td>
              <td><p>{{ work.classicVolume | formatNumber }}<span> kWh cumac</span></p></td>
              <td><p>{{ work.numberOfOperations }}</p></td>
              <td><p>€ {{ work.economicValorisation | formatNumber }}</p></td>
              <td>
                <b-badge
                    class="giga-badge"
                    :class="[
                        { inProgress: work.status == 'IN_PROGRESS' },
                        { delivered: work.status == 'DELIVERED' },
                        { deposited: work.status == 'DEPOSITED' },
                      ]"
                >
                  {{ $t(work.status) }}
                </b-badge>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {queryConstant} from "../../../shared/queryConstant";
import {constants} from "@/shared/constants";

export default {
  name: "tableWidget",
  props:{
    title:{
        type: String
    },
    URL:{
        type: String
    }
  },
  computed: {
    ...mapGetters('account', {currentUser: 'getCurrentUser'}),
    ...mapGetters('work', ['getWorks']),
    ...mapGetters('loader', { loading: 'getLoading' }),
    isB2BOrEligible(){
      return this.currentUser.clientType === this.USER_TYPE.B2B ||
          this.currentUser.clientType === this.USER_TYPE.BAILLEUR_SOCIAL;
    },
  },
  mounted() {
    this.setWorks({});
    if(this.currentUser.clientType === this.USER_TYPE.B2B2B && this.currentUser.role?.roleType === this.ROLE_TYPE.ADMIN_AGENCE){
      this.getWorksAdminAgence({
        currentPage: 1,
        perPage: 3,
        sortBy: 'id',
        sortDir: true,
        customFilter: queryConstant.setDefaultCustomFilter(),
        search: '',
        agenceId: this.currentUser.agence?.id
      });
    }
    else if((this.currentUser.clientType === this.USER_TYPE.B2B2B && this.currentUser.role?.roleType === this.ROLE_TYPE.USER) ||
            (this.isB2BOrEligible && this.currentUser.role?.roleType === this.ROLE_TYPE.USER))
    {
      this.getWorksChargeDeProjet({
        currentPage: 1,
        perPage: 3,
        sortBy: 'id',
        sortDir: true,
        customFilter: queryConstant.setDefaultCustomFilter(),
        search: ''
      });
    }
    else{
      this.getWorksAction({
        currentPage: 1,
        perPage: 3,
        sortBy: 'id',
        sortDir: true,
        customFilter: queryConstant.setDefaultCustomFilter(),
        search: ''
      });
    }
  },
  methods: {
    ...mapMutations('work', ['setWorks']),
    ...mapActions('work', ['getWorksAction', 'getWorksAdminAgence', 'getWorksChargeDeProjet']),
    onRowSelected(id) {
      this.$router.push({ name: "WorkDetail", params: { id: id } });
    },
  },
  data() {
    return {
      USER_TYPE: constants.USER_TYPE,
      ROLE_TYPE: constants.ROLE_TYPE,
    };
  },
};
</script>
